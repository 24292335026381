<ng-container *ngIf="disputeDetail !== undefined">
    <h2 mat-dialog-title>Dispute Details</h2>
    <mat-dialog-content>
        <mat-list>
            <mat-list-item>
                <h3 matLine>Description</h3>
                <p matLine>
                    <span>{{disputeDetail.transactionDescription}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="!disputeDetail.transactionDate">
                <h3 matLine>Transaction Date</h3>
                <p matLine>
                    <span>{{disputeDetail.transactionDate | date: 'shortDate'}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>Posting Date</h3>
                <p matLine><span>{{disputeDetail.transactionPostDate | date: 'shortDate'}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>Transaction Amount</h3>
                <p matLine><span>{{disputeDetail.transactionAmount | currency}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>Disputed Amount</h3>
                <p matLine><span>{{disputeDetail.disputeAmount | currency}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>Status</h3>
                <p matLine><span>{{disputeDetail.disputeStatus }}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>Reason</h3>
                <p matLine><span>{{disputeDetail.reasonDescription }}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>            
            <mat-list-item class='calcHeight' *ngIf="disputeDetail.infoComment">
                <h3 matLine>Additional Comments</h3>
                <p matLine><span>{{disputeDetail.infoComment}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="disputeDetail.intendedRecipientName">
                <h3 matLine>Recipient Name</h3>
                <p matLine><span>{{disputeDetail.intendedRecipientName}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>   
            <mat-list-item *ngIf="disputeDetail.intendedRecipientContact">
                <h3 matLine>Recipient Contact</h3>
                <p matLine><span>{{disputeDetail.intendedRecipientContact}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>     
            <mat-list-item *ngIf="disputeDetail.scamType">
                <h3 matLine>Scam Type</h3>
                <p matLine><span>{{disputeDetail.scamType}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>    
            <mat-list-item *ngIf="disputeDetail.contactMethodOfImposter">
                <h3 matLine>Contact Method Of Imposter</h3>
                <p matLine><span>{{disputeDetail.contactMethodOfImposter}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>    
            <mat-list-item *ngIf="disputeDetail.impersonatingEntityType">
                <h3 matLine>Impersonating Entity Type</h3>
                <p matLine><span>{{disputeDetail.impersonatingEntityType}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>                                        
            <mat-list-item>
                <h3 matLine>Dispute Type</h3>
                <p matLine><span>{{disputeDetail.disputeType }}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>Member Name</h3>
                <p matLine><span>{{disputeDetail.memberName }}</span>
                </p>
           </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>Member Number</h3>
                <p matLine><span>{{disputeDetail.memberNumber }}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>Source Type</h3>
                <p matLine><span>{{disputeDetail.accountDescription }}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>Dispute Date</h3>
                <p matLine><span>{{disputeDetail.appleSubmitDate | date: 'shortDate'}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>    
            <mat-list-item *ngIf="disputeDetail.submittedByTeller">
                <h3 matLine>Submitted by</h3>
                <p matLine><span>Teller# {{disputeDetail.submittedByTeller}} on {{disputeDetail.appleSubmitDate | date: 'shortDate'}}</span>
                </p>
            </mat-list-item>           
            <mat-divider></mat-divider>   
            <mat-list-item *ngIf="disputeDetail.lastModifiedTeller">
                <h3 matLine>Last Modified by</h3>
                <p matLine><span>Teller# {{disputeDetail.lastModifiedTeller}} on {{disputeDetail.modifiedDate | date: 'shortDate'}}</span>
                </p>
            </mat-list-item>                      
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="disputeDetail.resolvedByTeller">
                <h3 matLine>Processed by</h3>
                <p matLine><span>Teller# {{disputeDetail.resolvedByTeller}} on {{disputeDetail.resolutionDate | date: 'shortDate'}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>        
            <mat-list-item class='calcHeight' *ngIf="disputeDetail.resolutionReason">
                <h3 matLine>Reject Reason</h3>
                <p matLine><span>{{disputeDetail.resolutionReason}}</span>
                </p>
            </mat-list-item>
            <mat-divider></mat-divider>           
        </mat-list>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button mat-dialog-close>Close</button>
            <ng-container *ngIf="disputeDetail.disputeStatus == 'Open' && isAdmin">
                <button mat-raised-button color="warn" (click)="onClickReject()"[disabled]='btnstate'>Reject</button>
                <button mat-raised-button color="success" (click)="onClickApprove()"[disabled]='btnstate'>Approve</button>
                <button mat-raised-button color="primary" (click)="onClickEdit()" [disabled]='btnstate'>Edit</button>
            </ng-container>
            <ng-container *ngIf="disputeDetail.resolutionDate !== null && isAdmin">
                <button mat-raised-button color="warn" (click)="onClickReopen()"[disabled]='btnstate'>Reopen</button>
            </ng-container>
    </mat-dialog-actions>
</ng-container>