import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { AzureAdService } from './services/azure-ad.service';
import { Employee } from './shared/models/employee';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'disputes-dashboard';
  teller = new Employee();
  isUserLoggedIn: boolean = false;
  private readonly _destroy = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private azureAdService: AzureAdService
  ) {}

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (interactionStatus: InteractionStatus) =>
            interactionStatus == InteractionStatus.None
        ),
        takeUntil(this._destroy)
      )
      .subscribe((x) => {
        this.setLoginDisplay();
        this.azureAdService.setIsUserLoggedIn(this.isUserLoggedIn);
      });
  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  setLoginDisplay() {
    this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

}
